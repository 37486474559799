<div class="login center-block">
    <div class="row">
        <div class="col-xs-offset-right-1 col-xs-offset-left-1 left-block block-inline">
            <form autocomplete="off" [formGroup]="passwordForgotField" (ngSubmit)="passwordForgot(passwordForgotField)">
                <div class="form-group clearfix">
                    <div class="email-padding-top">
                        <label class="sr-only" for="email">Email</label>
                        <input maxlength="255" type="text" class="form-control" id="email" placeholder="Email" formControlName="email" autocomplete="off" autocomplete="smartystreets">
                        <span *ngIf="shouldDisplayRequired(['email'])" class="error"><i class="glyphicon glyphicon-remove border-radius"></i> Email is required</span>
                        <span *ngIf="shouldDisplayEmailValidation(['email'],'invalidEmail')" class="error"><i class="glyphicon glyphicon-remove border-radius"></i> Email is invalid</span>
                    </div>
                </div>
                <button type="submit" class="btn fnd-btn-primary fnd-btn-large text-left" [disabled]="!passwordForgotField.valid" (focus)="true">
                    <span class="glyphicon glyphicon-arrow-right" aria-hidden="true"></span>
                    Send
                    <i *ngIf="showLoader" class="glyphicon glyphicon-refresh glyphicon-refresh-animate loading-icon"></i>
                </button>

                <p class="errors">{{ formErrors.email }}</p>
            </form>
        </div>
    </div>
</div>