
<div class="login center-block">
    <div class="row">
        <div class="col-xs-offset-right-1 col-xs-offset-left-1 left-block block-inline signup-wrap">
            <form autocomplete="nada" [formGroup]="signUpFields" (ngSubmit)="signUp(signUpFields)" novalidate>
                <div class="form-group clearfix">
                    <div class="col-xs-6 form-pad">
                        <label class="sr-only" for="firstname">First Name</label>
                        <input maxlength="150" type="text" (input)="shouldDisplayRequired(['firstname'])" class="form-control" id="firstname" placeholder="First Name" formControlName="firstname" autocomplete="off" autocomplete="smartystreets">
                        <span *ngIf="formErrors.fname" class="error"><i class="glyphicon glyphicon-remove border-radius"></i>{{formErrors.fname}}</span>
                    </div>
                    <div class="col-xs-6 form-pad">
                        <label class="sr-only" for="lastname">Last Name</label>
                        <input maxlength="150" (input)="shouldDisplayRequired(['lastname'])" type="text" class="form-control" id="lastname" placeholder="Last Name" formControlName="lastname" autocomplete="off" autocomplete="smartystreets">
                        <span *ngIf="shouldDisplayRequired(['lastname'])" class="error"><i class="glyphicon glyphicon-remove border-radius"></i><!-- Last Name is required --></span>
                        <span *ngIf="formErrors.lname" class="error"><i class="glyphicon glyphicon-remove border-radius"></i>{{formErrors.lname}}</span>
                    </div>
                </div>
                <div class="form-group clearfix">
                    <div class="col-xs-12 form-pad">
                        <label class="sr-only" for="email">Email</label>
                        <input maxlength="255" type="email" (input)="shouldDisplayRequired(['email']); shouldDisplayEmailValidation(['email'],'invalidEmail')" class="form-control" id="email" placeholder="Email" formControlName="email" autocomplete="off" autocomplete="smartystreets">
                        <span *ngIf="formErrors.email" class="error"><i class="glyphicon glyphicon-remove border-radius"></i>{{formErrors.email}}</span>
                    </div>
                </div>
                <div class="form-group clearfix">
                    <div class="col-xs-12 form-pad">
                        <label class="sr-only" for="pass">Password</label>
                        <input maxlength="60" type="password" (input)="shouldDisplayRequired(['password'])" class="form-control" id="pass" placeholder="Password" formControlName="password" autocomplete="off" autocomplete="smartystreets">
                        <span *ngIf="formErrors.password" class="error"><i class="glyphicon glyphicon-remove border-radius"></i>{{formErrors.password}}</span>
                    </div>
                </div>
                <p *ngIf="formErrors.global" class="global-error">{{ formErrors.global }}</p>
                <div class="form-group checkbox text-left">
                    <label>
                        <input #agreeTerms type="checkbox" formControlName="terms" (change)="validateField($event, signUpFields)">
                        <span tabindex="0" (keyup)="checkV($event)" class="cr"><i class="cr-icon glyphicon glyphicon-ok"></i></span>
                        I Accept the <a href="http://findable-system.appspot.com.storage.googleapis.com/Findable-Terms-of-Use.docx">Terms and Conditions</a>
                        and <a href="http://findable-system.appspot.com.storage.googleapis.com/Findable-Privacy-Policy.docx">Privacy Policy</a>
                    </label>
                    <span *ngIf="formErrors.terms" class="error">
                        <i class="glyphicon glyphicon-remove border-radius"></i>
                        Please accept the Terms and Conditions and Privacy Policy
                    </span>
                </div>
                <input type="hidden" [value]="inviteCode" formControlName="invite">
                <input type="hidden" [value]="role" formControlName="role">
                <button type="submit" class="btn fnd-btn-primary fnd-btn-large text-left" [disabled]="!signUpFields.valid || showLoader || !termsError" (focus)="true">
                    <span class="glyphicon glyphicon-arrow-right" aria-hidden="true"></span>
                    Create Account
                    <i *ngIf="showLoader" class="glyphicon glyphicon-refresh glyphicon-refresh-animate loading-icon"></i>
                </button>
                <span class="block signup-link text-left terms-padding">
                    Already have an account?
                    <a [routerLink]="['/user/login']" queryParamsHandling>Click here to sign in</a>
                </span>
                <span *ngIf="userType == 'business'" class="block signup-link text-left terms-padding">
                    Looking to open an applicant account?
                    <a [routerLink]="['/user/signup']" queryParamsHandling>Click here</a>
                </span>
            </form>
        </div>
    </div>
</div>