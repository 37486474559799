
<div class="login center-block" >
	<form autocomplete="nada" [formGroup]="loginFields" (ngSubmit)="login($event, loginFields)" novalidate>

		<div (keyup)="onKeyDown($event)" class="form-group">
			<label class="sr-only" for="email">Email</label>
			<input maxlength="255" (input)=" shouldDisplayRequired(['email']); shouldDisplayEmailValidation(['email'],'invalidEmail')" type="email" class="form-control" id="email" placeholder="Email" formControlName="email" autocomplete="off" autocomplete="smartystreets">
            <span *ngIf="formErrors.email" class="error"><i class="glyphicon glyphicon-remove border-radius"></i>{{formErrors.email}}</span>
		</div>
		<div class="form-group">
			<label class="sr-only" for="password">Password</label>
			<input maxlength="60" (input)="shouldDisplayRequired(['password'])" type="password" class="form-control" id="password" placeholder="Password" formControlName="password" autocomplete="new-password">
            <span *ngIf="formErrors.password" class="error"><i class="glyphicon glyphicon-remove border-radius"></i>{{formErrors.password}}</span>
		</div>
		<div class="form-group checkbox">
			<label>
				<input #rememberCheckbox type="checkbox" id="rememberMe" formControlName="rememberMe" value='false'>
				<span tabindex="0" (keyup)="checkV($event)" class="cr"><i class="cr-icon glyphicon glyphicon-ok"></i></span>
				Keep me signed in
			</label>
		</div>

		<button tabindex="0" type="submit" class="btn fnd-btn-primary fnd-btn-large position" [disabled]="!loginFields.valid || showLoader" (focus)="true">
		<!-- <button type="submit" class="btn fnd-btn-primary fnd-btn-large position" (focus)="true"> -->
			<span class="glyphicon glyphicon-arrow-right" aria-hidden="true"></span>
			Sign in
			<i *ngIf="showLoader" class="glyphicon glyphicon-refresh glyphicon-refresh-animate loading-icon"></i>
		</button>
		
		<p class="error" id="login-error">{{formErrors.global}}</p>

		<span class="block signup-link">Forgot your password?
	        <a [routerLink]="['/user/password/forgot']" queryParamsHandling>Reset password</a>
        </span>
		<span class="block signup-link">Don't have an account?
	        <a [routerLink]="['/user/signup']" queryParamsHandling>Sign up</a>
        </span>


	</form>
</div>
