<div class="login center-block">
    <div class="row">
        <div class="col-xs-10 col-xs-push-1">
            <form autocomplete="off" [formGroup]="passwordConfirmFields" (ngSubmit)="passwordConfirm(passwordConfirmFields)">
                <div class="form-group clearfix" formGroupName="confirmPasswordGroup">
                    <div class="email-padding-top">
                        <label class="sr-only" for="newPassword">Password</label>
                        <input maxlength="60" type="password" (input)="resetErrors()" class="form-control" id="newPassword" placeholder="New password" formControlName="newPassword" autocomplete="off" autocomplete="smartystreets">
                    </div>
                </div>
                <div class="form-group margin-bottom clearfix" formGroupName="confirmPasswordGroup">
                    <div class="email-padding-top">
                        <label class="sr-only" for="repeatPassword">Password</label>
                        <input maxlength="60" type="password" (input)="resetErrors()" class="form-control" id="repeatPassword" placeholder="Repeat new password" formControlName="repeatPassword" autocomplete="off" autocomplete="smartystreets">
                    </div>
                    <p *ngIf="passwordMatched()" class="error"><i class="glyphicon glyphicon-remove border-radius"></i> Passwords do not match</p>
                    <p *ngIf="formErrors.global" class="error"><i class="glyphicon glyphicon-remove border-radius"></i> {{ formErrors.global }}</p>
                </div>
                
                
                <button type="submit" class="btn fnd-btn-primary fnd-btn-large text-left" [disabled]="!passwordConfirmFields.valid" (focus)="true">
                    <span class="glyphicon glyphicon-arrow-right" aria-hidden="true"></span>
                    Save new password
                </button>
                <div *ngIf="showLoader" class="load-page">
                    <i class="glyphicon glyphicon-refresh glyphicon-refresh-animate loading-icon"></i>
                </div>

                <span class="block signup-link">Forgot your password?
                    <a [routerLink]="['/user/password/forgot']">Click here</a>
                </span>
                        <span class="block signup-link">Don't have an account?
                    <a [routerLink]="['/user/signup']">Click here</a>
                </span>

            </form>
        </div>
    </div>
</div>